import { HttpClient } from '@angular/common/http';
import {
  Translation,
  TranslocoLoader,
  provideTransloco,
  TRANSLOCO_LOADER,
} from '@jsverse/transloco';
import { Inject, Injectable, isDevMode, Provider } from '@angular/core';
import { SettingsService } from '../services/settings.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable()
export class TranslocoHttpLoader implements TranslocoLoader {

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
    @Inject('APP_ENVIRONMENT') private environment: any
  ) {}

  getTranslation(lang: string) {
    return from(this.settings.waitForInitialization()).pipe(
      // tap(() => console.log('waitForInitialization completed')),
      switchMap(() => {
        const appName = this.settings.getAppName();
        return this.http.get<Translation>(`${this.settings.getHubBaseUrlApi}/api/${appName}/get-translation/${lang}`).pipe(
          catchError(error => {
            console.error('Error getting the translation:', error);
            return this.http.get<Translation>(`/i18n/${lang}.json`);
          })
        );
      }),
    );
  }
}

export const translocoConfig = {
  availableLangs: [
    { id: 'en', label: 'English' },
    { id: 'es', label: 'Español' },
    { id: 'he', label: 'עברית' },
    { id: 'pt', label: 'Português' }
  ],
  reRenderOnLangChange: true,
  fallbackLang: 'en',
  defaultLang: 'en',
  // prodMode: environment.production,
  missingHandler: {
    useFallbackTranslation: true,
    allowEmpty: !isDevMode(),
    logMissingKey: true,
  },
  // scopeMapping: {
  //   header: 'header',
  // },
};

export const provideTranslocoWithConfig = (): Provider[] => [
  { 
    provide: TRANSLOCO_LOADER,
    useClass: TranslocoHttpLoader,
    deps: [HttpClient, SettingsService]
  },
  provideTransloco({
    config: translocoConfig
  })
];