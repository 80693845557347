import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiResponse, MY_ACCOUNT_CONFIG } from '../../../public-api';
import { SettingsService } from 'lib-shared';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorVerificationService {
private config = inject(MY_ACCOUNT_CONFIG)

  constructor(private http:HttpClient, private settings:SettingsService) { }

  async enable2Factor<T>(token: string) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.VnumberApi.TwoFaVerification}`, { token2FA: token })
    const data = lastValueFrom(res)
    return data
  }
}
