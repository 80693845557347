import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { SettingsService } from 'lib-shared';
import { ControllerApiList } from '../../models/controllers/ControllerList';
import { FindCustomer } from '../../models/find-customer-model';
import { PortInInformationDto } from '../../models/controllers/portin-information.model';
import { ApiResponse } from '../../models/base/ApiResponse';

@Injectable({
  providedIn: 'root',
})
export class PortInService {
  constructor(private http: HttpClient, private settings: SettingsService) {}

  async validatePortInStatus<T>(sessionId: string) {
    const res = this.http.get<ApiResponse<T>>(
      `${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.PortInApi.ValidatePortInStatus}/${sessionId}`
    );
    const data = await lastValueFrom(res);

    return data;
  }

  async updateForwardinData<T>(sessionId: string, ForwardinData: any) {
    const url = `${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.PortInApi.ForwardingNumber}?sessionId=${encodeURIComponent(sessionId)}`;
    const res = this.http.put<ApiResponse<T>>(url, ForwardinData);
    const data = await lastValueFrom(res);
  
    return data;
  }
}
