import { Component, inject } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { BillingHistoryComponent } from '../billing-history/billing-history.component';
import { PaymentMethodsComponent } from '../payment-methods/payment-methods.component';
import {
  ICreditCardInfo,
  MY_ACCOUNT_CONFIG,
  PaymentMethodsService,
} from '../../../../../public-api';

@Component({
  selector: 'billing',
  standalone: true,
  imports: [BillingHistoryComponent, PaymentMethodsComponent, TranslocoModule],
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent {
  private paymentMethodsService = inject(PaymentMethodsService);
  private config = inject(MY_ACCOUNT_CONFIG);

  savedCards: ICreditCardInfo[] = [];
  showCreditCard: boolean = false;
  isEditing = false;
  editingCardId: number = 0;

  primaryColor: string = this.config.theme.primaryColor;

  ngOnInit(): void {
    this.getAllSavedCards();
  }

  onPaymentUpdated(): void {
    this.getAllSavedCards();
  }

  async getAllSavedCards() {
    try {
      const res = await this.paymentMethodsService.getAllSavedCreditCards<
        ICreditCardInfo[]
      >();

      if (res.isSuccessful && res.result) {
        this.savedCards = res.result;
      }
    } catch (error) {
      console.error(error);
    }
  }

  showCreditCardForm(): void {
    this.showCreditCard = true;
    this.isEditing = false;
    this.editingCardId = 0;
  }

  editCard(card: ICreditCardInfo): void {
    this.isEditing = true;
    this.editingCardId = card.creditCardId;
    this.showCreditCard = true;
  }

  onCloseModal() {
    this.showCreditCard = false;
  }
}
