import { Component, inject } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { ClickOutsideDirective, LoadingService, PopUpService, RecaptchaService } from 'lib-shared';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { AccountInfoService, AddCreditService, AuthService, MY_ACCOUNT_CONFIG, PaymentMethodsService } from '../../../../public-api';
import { ICreditCardInfo } from '../../../models/credit-card-info.model';
import { PaymentMethodsComponent } from '../billing-view/payment-methods/payment-methods.component';


@Component({
  selector: 'app-add-credit',
  standalone: true,
  imports: [
    CreditCardComponent,
    FormsModule,
    PaymentMethodsComponent,
    TranslocoModule,
    ClickOutsideDirective
  ],
  templateUrl: './add-credit.component.html',
  styleUrl: './add-credit.component.scss',
})
export class AddCreditComponent {

  private accountInfoService = inject(AccountInfoService);
  private addCreditService = inject(AddCreditService);
  private authService = inject(AuthService);
  private loadingService = inject(LoadingService);
  private paymentMethodsService = inject(PaymentMethodsService);
  private popUpService = inject(PopUpService);
  private recaptchaService = inject(RecaptchaService);
  private config = inject(MY_ACCOUNT_CONFIG)

  paymentID: number = 0;
  customAmount: string = '';

  confirmationID: number = 0;

  showConfirmationPopUp: boolean = false;
  showCreditCard: boolean = false;
  isNewMethod: boolean = false;
  termsAndConditions: boolean = true;
  isPrimary: boolean = false;
  keepCard: boolean = true;

  savedCards: ICreditCardInfo[] = [];

  primaryColor: string = this.config.theme.primaryColor;
  
  creditCardForm = this.paymentMethodsService.paymentMethodsForm.get(
    'creditCardFrom'
  ) as FormGroup;
  addressCardForm = this.paymentMethodsService.paymentMethodsForm.get(
    'addressCardForm'
  ) as FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.getAllSavedCards();
    // this.creditCardForm.patchValue({
    //   cardNumber: '4242424242424242',
    //   cardHolder: 'sfdfsdf sdfsdfds',
    //   cardExpirationMM: '09',
    //   cardExpirationYY: '2026',
    //   cardCVV: '234',
    // });

    // this.addressCardForm.patchValue({
    //   city: 'Montevideo',
    //   state: '4510',
    //   zipCode: '11200',
    //   address: '2119 Colonia',
    //   country: '217',
    // })
  }

  selectAmount(amount: string): void {
    this.customAmount = amount;
  }

  onSelectedPaymentMethod(paymentId: number) {
    this.paymentID = paymentId;
  }

  onCloseConfirmationPopUp() {
    this.showConfirmationPopUp = false;
    this.accountInfoService.onCreditAdded(true);
    this.customAmount = '';
  }

  numberOnly(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[^0-9]/g, '');
    if (value.length > 2) {
      value = value.slice(0, 2);
    }

    const numValue = parseInt(value, 10);
    if (numValue > 99) {
      value = '99';
    } else if (numValue === 0 && value !== '') {
      value = '1';
    }

    this.customAmount = value;
    input.value = this.customAmount;
  }

  shouldDisableButton(): boolean {
    if (this.customAmount === '') return true;

    if (!this.termsAndConditions) return true;

    if (this.isNewMethod) {
      return this.creditCardForm.invalid || this.addressCardForm.invalid;
    } else {
      return this.paymentID === 0;
    }
  }

  showCreditCardForm(): void {
    this.showCreditCard = true;
  }

  onAddCreditFromNewCard() {
    this.isNewMethod = true;
    this.addCredit();
  }

  async getAllSavedCards() {
    try {
      const res = await this.paymentMethodsService.getAllSavedCreditCards<
        ICreditCardInfo[]
      >();

      if (res.isSuccessful && res.result) {
        this.savedCards = res.result;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async addPaymentMethod() {
    const {
      cardNumber,
      cardHolder,
      cardExpirationMM,
      cardExpirationYY,
      cardCVV,
      isPrimaryCard,
    } = this.creditCardForm.value;

    const { city, state, zipCode, address, country } =
    this.addressCardForm.value;

    const token = await this.recaptchaService.executeRecaptcha('addCreditCard')
    
    const jsonData = {
      CreditCardNumber: cardNumber,
      CardHolder: cardHolder,
      ExpirationMonth: Number(cardExpirationMM),
      ExpirationYear: Number(cardExpirationYY),
      SecurityCode: cardCVV,
      IsPrimary: isPrimaryCard ? 1 : 0,
      City: city,
      State: state,
      Zip: zipCode,
      Address: address,
      CountryNumber: country.toString(),
      IsSaveCard: 1, // always true in this scenario
      recaptcha: token
    };

    try {
      const res = await this.paymentMethodsService.createCreditCard(jsonData);

      if (res.response_code === 200) {
        console.log('tarjeta guardada')
        await this.getAllSavedCards();
      }

    } catch (error) {}
  }

  async addCredit() {
    this.loadingService.setLoading(true);
    const amountToAdd = Number(this.customAmount) || 0;
    let jsonData;
    const token = await this.recaptchaService.executeRecaptcha(
      'vNumberPurchase'
    );

    if (this.isNewMethod) {
      const {
        cardNumber,
        cardHolder,
        cardExpirationMM,
        cardExpirationYY,
        cardCVV,
        isPrimaryCard,
        keepCard,
      } = this.creditCardForm.value;

      const { city, state, zipCode, address, country } =
        this.addressCardForm.value;

      if (keepCard) this.addPaymentMethod();

      jsonData = {
        CreditCardNumber: cardNumber,
        CardHolder: cardHolder,
        ExpirationMonth: Number(cardExpirationMM),
        ExpirationYear: Number(cardExpirationYY),
        SecurityCode: cardCVV,
        IsPrimary: isPrimaryCard ? 1 : 0,
        City: city,
        State: state,
        Zip: zipCode,
        Address: address,
        CountryNumber: country,
        IsSaveCard: keepCard ? 1 : 0,
        Amount: amountToAdd,
        recaptcha: token
      };
    } else {
      jsonData = {
        PaymentId: this.paymentID,
        Amount: amountToAdd,
        recaptcha: token
      };
    }

    try {
      const res = await this.addCreditService.addCredit(jsonData);

      if (res.response_code === 200) {
        this.loadingService.setLoading(false);
        this.confirmationID = res.confirmationId;
        this.showConfirmationPopUp = true;
        this.showCreditCard = false;
        // this.customAmount = ''; 
      }

      if (res.response_code === 100) {
        this.popUpService.openPopUp('server-error');
        this.loadingService.setLoading(false);
      }
    } catch (error) {
      this.loadingService.setLoading(false);
      this.popUpService.openPopUp('server-error');
      console.error(error);
    }
  }
}
