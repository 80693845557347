import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideMyAccount } from 'lib-my-account';
import { provideTranslocoWithConfig, SettingsService } from 'lib-shared';
import { ControllerApiList } from './models/controllers/ControllerList';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes, withViewTransitions()), 
    provideClientHydration(),
    { 
      provide: 'APP_ENVIRONMENT', 
      useValue: environment
    },
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideTranslocoWithConfig(),
    provideTranslocoMessageformat(),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        es: 'es-ES',
        he: 'he-IL',
        pt: 'pt-BR',
      },
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: (settings: SettingsService) => {
        return () => settings.updateSettings({ appName: 'globalweb' });
      },
      deps: [SettingsService],
      multi: true
    },
    provideMyAccount({
      apiBaseUrl: '',
      sidebarConfig: {
        availableCreditEnabled: false,
        items: [
          {
            path: '/profile/port-in',
            icon: '/images/icons/sim.svg',
            iconActive: '/images/icons/sim-w.svg',
            labelKey: 'port-in'
          }
        ]
      },
      controllerApiList: {
        HubApi: {
          SignIn: `${ControllerApiList.HubApi.GlobalWebApi.SignIn}`,
          ResetPassword: '',
          ValidateCustomerPassword: '',
          GetAccountInfo: '',
          VerifyEmailChange: '',
          ValidateCustomerEmail: '',
          Inquiry: `${ControllerApiList.HubApi.Inquiry}`
        }
      },
      title: "",
      logOut: 'globalweb.buttons.log-out',
      theme: {
        // Define aquí las configuraciones de tema
        primaryColor: '#FF5733',
        secondaryColor: '#4A90E2',
        logo: '/images/logo.webp'
        // Otras configuraciones de tema que necesites
      },
    }),
  ]
};
