import { Component, inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { LangDefinition, TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { DeleteAccountModalComponent } from '../delete-account-modal/delete-account-modal.component';
import { AccountInfoService, MY_ACCOUNT_CONFIG } from '../../../../../public-api';
import { IAccountInformation, IAccountPatch } from '../../../../models/user-information.model';
import { PopUpService } from 'lib-shared';
import { CommonModule } from '@angular/common';
// import { LoadingService } from '../../../../../services/loading/loading.service';

@Component({
  selector: 'app-account-info',
  standalone: true,
  imports: [
    CommonModule,
    DeleteAccountModalComponent,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  templateUrl: './account-info.component.html',
  styleUrl: './account-info.component.scss',
})
export class AccountInfoComponent {
  private accountInfoService = inject(AccountInfoService);
  // loadingService = inject(LoadingService);
  private popUpService = inject(PopUpService);
  private translocoService = inject(TranslocoService);
  private config = inject(MY_ACCOUNT_CONFIG);

  userData: IAccountInformation | null = null;
  updatePatch: IAccountPatch | null = null;

  isEmailEditable: boolean = false;
  isPasswordEditable: boolean = false;
  formSubmitted: boolean = false;
  showDeleteAccountModal: boolean = false;

  availableLangs =
    this.translocoService.getAvailableLangs() as LangDefinition[];

  primaryColor: string = this.config.theme.primaryColor;

  profileForm: FormGroup;
  changePasswordForm: FormGroup;
  changeEmailForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.profileForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      language: ['', Validators.required],
    });
  
    this.changePasswordForm = this.fb.group(
      {
        currentPassword: ['', [Validators.required]],
        newPassword: [
          '',
          [Validators.required, Validators.minLength(8), this.passwordValidator],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: this.passwordMatchValidator() }
    );
  
    this.changeEmailForm = this.fb.group(
      {
        newEmail: ['', [Validators.required, Validators.email]],
        confirmEmail: ['', [Validators.required, Validators.email]],
      },
      { validators: this.emailsMatchValidator() }
    );
  }

  ngOnInit() {
    this.getAccountInfo();
  }

  emailsMatchValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newEmail = form.get('newEmail')?.value;
      const confirmEmail = form.get('confirmEmail')?.value;
      return newEmail === confirmEmail ? null : { emailsMismatch: true };
    };
  }

  passwordMatchValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newPassword = form.get('newPassword')?.value;
      const confirmPassword = form.get('confirmPassword')?.value;
      return newPassword === confirmPassword
        ? null
        : { passwordMismatch: true };
    };
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!/[A-Z]/.test(value)) {
      return { noUpperCase: true };
    }

    if (!/[A-Za-z]/.test(value)) {
      return { noLetter: true };
    }

    if (!/[0-9]/.test(value)) {
      return { noNumber: true };
    }
    return null;
  }

  onDeleteAccount() {
    this.showDeleteAccountModal = true
  }

  // close delete account modal
  onCloseModal(confirm: boolean) {
    this.showDeleteAccountModal = false;

    // if (confirm) this.popUpService.openPopUp('delete-account');
  }

  async getAccountInfo() {
    try {
      const res =
        await this.accountInfoService.getAccountInfo<IAccountInformation>();

      if (res.isSuccessful && res.result) {
        this.userData = res.result;

        this.profileForm.patchValue({
          firstName: this.userData?.firstName,
          lastName: this.userData?.lastName,
          language: this.userData?.language,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async updateFullNameAndLanguage() {
    const patchOperations = [];

    if (this.profileForm.get('firstName')?.dirty) {
      patchOperations.push({
        op: 'replace',
        path: '/Subscriber/firstName',
        value: this.profileForm.get('firstName')?.value,
      });
    }
    if (this.profileForm.get('lastName')?.dirty) {
      patchOperations.push({
        op: 'replace',
        path: '/Subscriber/lastName',
        value: this.profileForm.get('lastName')?.value,
      });
    }
    if (this.profileForm.get('language')?.dirty) {
      patchOperations.push({
        op: 'replace',
        path: '/Subscriber/language',
        value: this.profileForm.get('language')?.value,
      });
    }

    let result = await this.updateAccountInfoPatch(patchOperations);

    if (result.subscriberResult === true) {
      this.profileForm.markAsPristine(); // form marked as not dirty
      this.getAccountInfo();
      this.accountInfoService.onInfoUpdated(true)
      // this.loadingService.setLoading(false);
    }
  }

  async updatePassword() {
    this.formSubmitted = true;
    if (this.changePasswordForm.valid) {
      const patchOperations = [
        {
          op: 'replace',
          path: '/Customer/CurrentPassword',
          value: this.changePasswordForm.get('currentPassword')?.value,
        },
        {
          op: 'replace',
          path: '/Customer/NewPassword',
          value: this.changePasswordForm.get('newPassword')?.value,
        },
      ];

      let result = await this.updateAccountInfoPatch(patchOperations);

      if (result.customerResult === true) {
        this.getAccountInfo();
        this.formSubmitted = false;
        this.isPasswordEditable = false;
        this.changePasswordForm.reset()
        // this.loadingService.setLoading(false);
        this.popUpService.openPopUp('password-changed-success');
      } else {
        // this.loadingService.setLoading(false);
        this.popUpService.openPopUp('wrong-current-password');
      }
    }
  }

  async updateAccountInfoPatch(operation: any[]): Promise<IAccountPatch> {
    // this.loadingService.setLoading(true);
    let patchResult: IAccountPatch = {
      customerResult: false,
      subscriberResult: false,
    };

    try {
      const res =
        await this.accountInfoService.updateAccountInfo<IAccountPatch>(
          operation
        );

      if (res.isSuccessful && res.result) patchResult = res.result;
    } catch (error) {
      console.error(error);
    }

    return patchResult;
  }

  async updateEmail() {
    this.formSubmitted = true;
    if (this.changeEmailForm.valid) {
      const jsonData = {
        Email: this.changeEmailForm.get('newEmail')?.value,
        LanguageIso: this.profileForm.get('language')?.value,
      };

      try {
        const res = await this.accountInfoService.verifyEmailChange(jsonData);

        if (res.isSuccessful && res.result) {
          this.formSubmitted = false;
          this.popUpService.openPopUp('verifying-email')
          this.isEmailEditable = false;
        }
      } catch (error: any) {
        console.error(error);

        if (error.error.isSuccessful === false && error.error.result === false) {
          this.formSubmitted = false;
          this.popUpService.openPopUp('email-already-exist');
        }
      }
    }
  }
}
