import { Component } from '@angular/core';

@Component({
  selector: 'lib-lib-my-account',
  standalone: true,
  imports: [],
  template: `
    <p>
      lib-my-account works!
    </p>
  `,
  styles: ``
})
export class LibMyAccountComponent {

}
