<div class="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
  <div class="flex justify-center flex-1 items-center gap-3">
    @if (isLoading) {
    <!-- <app-spinner-small></app-spinner-small> -->
    <div class="loader loader--style3" title="2">
      <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" width="34px" height="34px" [class]="'text-' + primaryColor" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;"
        xml:space="preserve">
        <path fill="currentColor"
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
          <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25"
            dur="0.6s" repeatCount="indefinite" />
        </path>
      </svg>
    </div>
    }@else {
    <button (click)="handlePrevious()" [disabled]="pageIndex === 1" transloco="btn-vn.previous"
      class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"></button>
    <button (click)="handleNext()" [disabled]="!hasMoreRecords" transloco="btn-vn.next"
      class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"></button>
    }
  </div>
</div>