<main class="flex flex-col items-center px-4 py-10 min-h-screen">
  <h2 class="flex flex-col items-center text-center py-10">
    <strong [class]="'text-' + primaryColor " transloco="profile.2fa.title">Two-Factor Authentication</strong>
    <span class="text-2xl">{{ email }}</span>
  </h2>

  @if (emailSent) {
    <p transloco="profile.2fa.email-sent" class="text-center max-w-96">
      We have sent you an email with instructions to enable Two-Factor Authentication.
      Please check your inbox and follow the steps.
    </p>
  }@else { 
    <p  transloco="profile.2fa.enable" class="text-center max-w-96">
      Would you like to enable two-factor authentication for extra security?
    </p>
    
    <div class="flex items-center gap-2 mt-10">
      @if (isLoading) {
        <div class="loader loader--style3" title="2">
          <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             width="34px" height="34px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
          <path fill="#a3a3a3" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
            <animateTransform attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.6s"
              repeatCount="indefinite"/>
            </path>
          </svg>
        </div>
      }@else {
        <button class="{{'bg-'+ primaryColor}} text-white px-12 py-1 rounded-full hover:scale-105 transition-all" (click)="enableTwoFactor()" transloco="btn-vn.enable">Enable 2FA</button>
        <button class="skip-btn" (click)="skipTwoFactor()" transloco="btn-vn.skip">Skip</button>
      }
      </div>
  }

  <!-- <div *ngIf="token2fa">
    <p>Enter your 2FA token:</p>
    <input type="text" [(ngModel)]="token2fa" placeholder="Enter token" />
    <button class="verify-btn" (click)="verifyToken()">Verify</button>
  </div> -->
</main>