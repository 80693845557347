import { provideHttpClient } from "@angular/common/http";
import { MY_ACCOUNT_CONFIG, MyAccountConfig } from "../config/my-account.config";
import { MyAccountApiService } from "../services/my-account-api.service";

export function provideMyAccount(config: MyAccountConfig) {
  return [
    {
      provide: MY_ACCOUNT_CONFIG,
      useValue: config
    },
    MyAccountApiService,
    provideHttpClient(),
    // Otros providers necesarios
  ];
}