<div class="flex flex-col gap-8">
  <section class="rounded-xl shadow-full border border-greyLight py-8 px-6 md:px-16 w-full">
    <h2 class="text-2xl font-bold mb-16" transloco="profile-vn.billing.payment-methods.title"></h2>
    <div class="font-semibold text-sm pb-2">
      <span transloco="profile-vn.billing.payment-methods.you-have"></span>
      
      <span>&nbsp;{{ savedCards.length }}</span>
      <span
        [transloco]="savedCards.length !== 1 ? 'profile-vn.billing.payment-methods.saved-cards' : 'profile-vn.billing.payment-methods.saved-card'"></span>
    </div>

    <article class="flex flex-wrap gap-4">
      @for (card of savedCards; track $index) {
      <div (click)="editCard(card)"
        class="relative flex items-center rounded-2xl shadow-xl px-4 py-6 border border-dashed border-greyDark w-max hover:scale-105 hover:shadow-md cursor-pointer">
        <div class=" flex">
          @if(card.creditCardType !== '') {
          <img [src]="'/images/icons/payment/' + card.creditCardType + '.svg'" alt="card type"
            class="w-12 h-9 my-auto">
          }@else {
          <img [src]="'/images/icons/payment/chip.svg'" alt="card type" class="w-12 h-9 my-auto">
          }
          <div class="px-4">
            <h6 class=" text-sm font-semibold">•••• •••• {{ card.lastFourNumbers }}</h6>
            <div class="text-xs text-grey">
              <span transloco="profile-vn.billing.payment-methods.expires-on"></span>
              {{ card.expirationMonth + '/' + card.expirationYear.slice(-2) }}
            </div>
          </div>
          <svg class="w-3 h-3 mx-1 my-auto" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.10376 5.98301C5.21265 6.09392 5.35631 6.15 5.50002 6.15C5.64373 6.15 5.78739 6.09392 5.89628 5.98301L9.98715 1.8164C9.98716 1.81638 9.98717 1.81637 9.98718 1.81636C10.2044 1.59523 10.2041 1.23813 9.98722 1.01705C9.76865 0.794296 9.41329 0.794346 9.19466 1.01702L5.50002 4.78007L1.80541 1.01705C1.8054 1.01704 1.80539 1.01703 1.80538 1.01702C1.58676 0.794252 1.23145 0.794517 1.01289 1.01699C0.795686 1.23807 0.795733 1.59522 1.01285 1.81636L5.10376 5.98301Z"
              fill="#757575" stroke="#757575" stroke-width="0.3" />
          </svg>
        </div>
        @if(card.isPrimary === true) {
        <span>
          <img src="/images/icons/profile/star.svg" alt="" class="w-16 absolute -top-1.5 ltr:-right-3 rtl:-left-3 rtl:-scale-x-100">
        </span>
        }
      </div>
      }
    </article>

    <div class="pt-12">
      <button type="button" (click)="showCreditCardForm()"
        [class]="'flex items-center justify-center text-white font-semibold w-full sm:w-fit px-10 sm:px-16 py-3 rounded-xl hover:scale-105 bg-' + primaryColor">
        <span transloco="btn-vn.add-payment-method"></span>
        <img src="/images/icons/arrow-icon.svg" alt="white arrow" class="w-[1.8rem] pb-1 h-fit rtl:-scale-x-100">
      </button>
    </div>

  </section>

  <app-billing-history></app-billing-history>

  @if (showCreditCard) {
  <app-payment-methods [isEditing]="isEditing" [editingCardId]="editingCardId" (closeModalEvent)="onCloseModal()"
    (paymentUpdated)="onPaymentUpdated()">
  </app-payment-methods>
  }
</div>