<!-- <app-info-pop-up 
  *ngIf="popUpService.showPopUp()" 
  [showPopUp]="popUpService.showPopUp()"
  (closePopUpEvent)="onClosePopUp()" 
  [translate]="popUpService.popUpMessage()">
</app-info-pop-up> -->

<div [dir]="textDir">
  <app-header></app-header> 
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <app-footer></app-footer>
</div>