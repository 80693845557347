
export const ControllerApiList = {
    WebApi: {
      States: "api/Country/GetStatesByContryID",
      GetSimPrice: "api/simorder/GetSimPrices",
      PromoCodeValidation: "api/customer/ValidateCoupon",
      ContactUsInquiry: "api/Inquiry/CreateInquiry",
      Countries: "api/country/GetCountries",
      FindCustomer: "api/customer/FindCustomer",
      CreateInqTicket: "api/Inquiry/CreateInquiry",
      CheckSMSCode: "api/Customer/ValidateCode",
      SubmitOrder: "api/Customer/CreatevalidatedSubscriber",
      GetPackageInfo: "api/Package/PackageInfo",
      ResetPassword: "api/account/PasswordReset",
      AddCredit: "api/payment/AddCreditValidated"
    },
    HubApi: {
      GetCountries: "api/globalhub/countries",
      Inquiry: "api/inquiry",
      PortInApi: { 
        PhoneRequest: "api/portin/PhoneRequest", 
        CheckSMSCode: "api/portin/PhoneRequestSmsCode",
        ValidatePortInStatus: "api/portin/ValidatePortInStatus",
        ForwardingNumber: "api/portin/ForwardingNumber",
      },
      GlobalWebApi: {
        SignIn: "api/globalweb/auth/Login",
        SignInPortIn: "api/globalweb/portin/auth/Login",
      }
    }
}

