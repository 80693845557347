import {
  Component,
  ElementRef,
  Inject,
  inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import intlTelInput from 'intl-tel-input';
import { CommonModule } from '@angular/common';
import { LocalStorageList, PersistenceService } from 'lib-my-account';
import { SmsValidationService } from '../../../../services/sms-validation/sms-validation.service';
import { PopUpService } from 'lib-shared';
import { InfoPopUpComponent } from '../../pop-up/info-pop-up/info-pop-up.component';
import { ActivatedRoute } from '@angular/router';
import { PortInService } from '../../../../services/port-in/port-in.service';
import { PortInInformationDto } from '../../../../models/controllers/portin-information.model';
import { ForwardingSettingsComponent } from '../forwarding-settings/forwarding-settings.component';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-verification',
  standalone: true,
  imports: [
    CommonModule,
    ForwardingSettingsComponent,
    ReactiveFormsModule,
    FormsModule,
    InfoPopUpComponent,
    TranslocoModule,
  ],
  templateUrl: './port-in-verification.component.html',
  styleUrl: './port-in-verification.component.scss',
})
export class PortInVerificationComponent {
  private persistenceService = inject(PersistenceService);
  smsValidationService = inject(SmsValidationService);
  popUpService = inject(PopUpService);

  formGroup: FormGroup;
  formSubmitted = false;
  resendTimer = 0;

  @ViewChild('phoneInput') phoneInput!: ElementRef;

  phoneNumber: string = '';
  verificationCode: string = '';
  codeSent: boolean = false;
  portInToken: string = '';
  formBuilder = inject(FormBuilder);
  route = inject(ActivatedRoute);
  portInService = inject(PortInService);
  translocoService = inject(TranslocoService);

  showPopUp: boolean;
  popUpMessage: string = '';
  token = this.route.snapshot.queryParamMap.get('sessionid');
  loading: boolean = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.formGroup = this.formBuilder.group({
      phone: ['', Validators.required, this.phoneNumberValidator],
      dialCode: [''],
    });

    this.portInToken = this.persistenceService.getData(
      LocalStorageList.currentUser,
      true
    ).token;
  }

  ngOnInit() {
    this.validatePortInStatus();

    if (this.platformId === 'browser') {
      this.inputElementInit();
    }
  }

  portInStatus: PortInInformationDto;
  async validatePortInStatus() {
    try {
      this.loading = true;
      let result =
        await this.portInService.validatePortInStatus<PortInInformationDto>(
          this.token
        );
      this.loading = false;
      if (result && result.isSuccessful) {
        if (result.result) {
          //   console.log(result.result);
          this.portInStatus = result.result;
        } else {
          this.popUpMessage =
            'There was a problem and we were unable to view the information.';
          this.openPopUp();
        }
      } else {
        if (result.isError) {
          this.popUpMessage = result.errorMessage;
          this.openPopUp();
        } else {
          const concatenatedMessages = result.messages
            .map((msg) => msg.message)
            .join(', ');
          this.popUpMessage = concatenatedMessages;
          this.openPopUp();
        }
      }
    } catch (error) {
      if (error && error['error']) {
        const concatenatedMessages = error['error']['messages']
          .map((msg) => msg.message)
          .join(', ');
        this.popUpMessage = concatenatedMessages;
        this.openPopUp();
      }
    }
  }

  tryToLoadIntlTelInput = 0;
  tryToLoadIntlTelInputMax = 10;
  async inputElementInit() {
    if (
      !this.phoneInput &&
      this.tryToLoadIntlTelInput <= this.tryToLoadIntlTelInputMax
    ) {
      setTimeout(() => {
        this.inputElementInit();
        this.tryToLoadIntlTelInput++;
      }, 250);

      return;
    }

    if (!this.phoneInput?.nativeElement) return;

    const inputElement = this.phoneInput.nativeElement;

    if (inputElement) {
      const iti = intlTelInput(inputElement, {
        //initialCountry: this.portInStatus?.trunkProviderPrefix,
        separateDialCode: true,
        formatOnDisplay: true,
        strictMode: true,
        loadUtilsOnInit: `https://cdn.jsdelivr.net/npm/intl-tel-input@${intlTelInput.version}/build/js/utils.js`,
      });

      iti.setNumber(`+${this.portInStatus?.trunkProviderPrefix}`);

      /* if(this.portInStatus?.phoneNumber){        
        iti.setNumber(this.portInStatus?.phoneNumber);
        this.applyPhoneNumberInputValue(iti);     

      }else if(this.portInStatus?.phoneNumberInitial){        
        iti.setNumber(`+${this.portInStatus?.phoneNumberInitial}`);
        this.applyPhoneNumberInputValue(iti);
      }*/

      inputElement.addEventListener('input', () => {
        this.applyPhoneNumberInputValue(iti);
      });
    }
  }

  applyPhoneNumberInputValue(iti: any) {
    setTimeout(() => {
      const phoneNumber = iti.getNumber();
      const dialCode = iti.getSelectedCountryData().dialCode;
      const nationalNumber = phoneNumber.replace(`+${dialCode}`, '').trim();

      this.formGroup.get('phone')?.setValue(nationalNumber);
      this.formGroup.get('dialCode')?.setValue(dialCode);
      this.formGroup.get('phone')?.updateValueAndValidity();

      //console.log(this.formGroup.get('phone')?.value);
      //console.log(this.formGroup.get('dialCode')?.value);
    }, 250);
  }

  onSubmit() {
    if (!this.codeSent) {
      this.sendSMS();
    } else {
      this.verifyCode();
    }
  }

  async sendSMS() {
    if (this.formGroup.valid) {
      this.formSubmitted = true;

      let data = {
        Token: this.portInToken,
        PhoneNumber: this.formGroup.get('phone')?.value,
        DialCode: this.formGroup.get('dialCode')?.value,
      };

      try {
        let result = await this.smsValidationService.sendSMSCode(data);
        if (result && result.isSuccessful) {
          if (result.result === true) {
            this.codeSent = true;
            this.startResendTimer();
          } else {
            this.popUpMessage =
              'There has been a problem and we have not been able to make the request';
            this.openPopUp();
          }
        } else {
          if (result.isError) {
            this.popUpMessage = result.errorMessage;
            this.openPopUp();
          } else {
            const concatenatedMessages = result.messages
              .map((msg) => msg.message)
              .join(', ');
            this.popUpMessage = concatenatedMessages;
            this.openPopUp();
          }
        }
      } catch (error) {
        if (error && error['error']) {
          const concatenatedMessages = error['error']['messages'].map(
            (msg) => msg.message
          );

          console.log('error', concatenatedMessages.join(', '));

          switch (concatenatedMessages[0]) {
            case 'number:is invalid; ':
              this.popUpMessage = this.translocoService.translate(
                'globalweb.portin.popups.invalid-phone-number'
              );
              break;
            case 'status:already authenticated; ':
              this.popUpMessage = this.translocoService.translate(
                'globalweb.portin.popups.status-already-authenticated'
              );
              break;
            default:
              this.popUpMessage = concatenatedMessages.join(', ');
              break;
          }

          this.openPopUp();
        }
      }
    }
  }

  openPopUp() {
    this.showPopUp = true;
    //this.popUpService.openPopUp(this.popUpMessage)
  }

  onClosePopUp() {
    this.showPopUp = false;
  }

  async verifyCode() {
    try {
      let confirmationData = {
        Token: this.portInToken,
        SmsCode: this.verificationCode,
      };
      let result = await this.smsValidationService.checkSMSCode(
        confirmationData
      );

      if (result && result.isSuccessful) {
        if (result.result === true) {
          this.popUpMessage = this.translocoService.translate(
            'globalweb.portin.popups.port-in-in-process'
          );
          this.openPopUp();

          this.codeSent = false;
          this.formGroup.reset();

          this.validatePortInStatus();
        } else {
          this.popUpMessage =
            'There has been a problem and we have not been able to make the request';
          this.openPopUp();
        }
      } else {
        if (result.isError) {
          this.popUpMessage = result.errorMessage;
          this.openPopUp();
        } else {
          const concatenatedMessages = result.messages
            .map((msg) => msg.message)
            .join(', ');
          this.popUpMessage = concatenatedMessages;
          this.openPopUp();
        }
      }
    } catch (error) {
      if (error && error['error']) {
        const concatenatedMessages = error['error']['messages']
          .map((msg) => msg.message)
          .join(', ');
        this.popUpMessage = concatenatedMessages;
        this.openPopUp();
      }
    }
  }

  phoneNumberValidator(
    control: FormControl
  ): Promise<{ [key: string]: boolean } | null> {
    return new Promise((resolve) => {
      const cleanedNumber = control.value;

      if (cleanedNumber) {
        const input = document.querySelector('#phoneInput') as HTMLInputElement;
        const iti = intlTelInput.getInstance(input);

        if (iti && iti.isValidNumber()) {
          resolve(null);
        } else {
          resolve({ invalidPhoneNumber: true });
        }
      } else {
        resolve(null);
      }
    });
  }

  startResendTimer() {
    this.resendTimer = 60;
    const timer = setInterval(() => {
      this.resendTimer--;
      if (this.resendTimer <= 0) {
        clearInterval(timer);
        this.codeSent = false;
      }
    }, 1000);
  }
}
