/*
 * Public API Surface of lib-shared
 */

// SERVICES
export * from './lib/services/settings.service';
export * from './lib/services/loading.service';
export * from './lib/services/pop-up.service';

// TRANSLOCO
export * from './lib/transloco/transloco-root.service';