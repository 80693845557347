import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SettingsService } from 'lib-shared';
import { AuthService } from '../auth/auth.service';
import { MY_ACCOUNT_CONFIG } from '../../config/my-account.config';
import { IAddCredit } from '../../models/add-credit.model';

@Injectable({
  providedIn: 'root'
})
export class AddCreditService {
  private config = inject(MY_ACCOUNT_CONFIG);
  private authService = inject(AuthService);
  
  constructor(private http: HttpClient, private settings: SettingsService) { }

  async addCredit(credit: any) {

    const headers = new HttpHeaders().set('token', this.authService.getToken())

    const res = this.http.post<IAddCredit>(`${this.settings.getBaseUrlApi}/${this.config.controllerApiList.WebApi.AddCredit}`, credit,  { 'headers': headers });
    const data = await lastValueFrom(res);
    return data
  }
}
