import { Component, Inject } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';
import { MY_ACCOUNT_CONFIG, MyAccountConfig } from '../../../config/my-account.config';

@Component({
  selector: 'app-profile-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslocoModule],
  templateUrl: './profile-sidebar.component.html',
  styleUrls: ['./profile-sidebar.component.scss'],
})
export class ProfileSidebarComponent {
  constructor(
    private router: Router,

    @Inject(MY_ACCOUNT_CONFIG) public config: MyAccountConfig
  ) {}

  // isActive(route: string): boolean {
  //   return this.router.isActive(route, true);
  // }

  sidebarItems = [];

  ngOnInit() {
    this.sidebarItems = this.config.sidebarConfig.items;
  }

  isActive(path: string): boolean {
    return this.router.url.startsWith(path);
  }
}
