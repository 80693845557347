import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SettingsService } from 'lib-shared';
import { lastValueFrom } from 'rxjs';
import { ApiResponse, MY_ACCOUNT_CONFIG } from '../../../public-api';

@Injectable({
  providedIn: 'root'
})
export class StatesService {
  private config = inject(MY_ACCOUNT_CONFIG)
  constructor(private http:HttpClient, private settings:SettingsService) { }

  async getStates<T> (countryId: number) {
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.GetCountries}/${countryId}/states`)
    const data = await lastValueFrom(res)
    return data
  }
}
