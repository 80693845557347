import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AuthService, ApiResponse, OldApiResponse, MY_ACCOUNT_CONFIG } from '../../../public-api';
import { SettingsService } from 'lib-shared';


@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsService {
  private authService = inject(AuthService);
  private config = inject(MY_ACCOUNT_CONFIG)

  paymentMethodsForm: FormGroup

  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private settings: SettingsService
  ) {
    this.paymentMethodsForm = this.formBuilder.group({
      creditCardFrom: this.formBuilder.group({
        cardHolder: [
          '',
          Validators.compose([Validators.required, Validators.minLength(3)]),
        ],
        cardNumber: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(13),
            Validators.maxLength(19),
            this.creditCardValidator,
          ]),
        ],
        cardExpirationMM: ['', Validators.compose([Validators.required])],
        cardExpirationYY: ['', Validators.compose([Validators.required])],
        cardCVV: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
            Validators.pattern(/^\d{3,4}$/),
          ]),
        ],
        isPrimaryCard: [false],
        keepCard: [true],
        termsAndConditions: [true, Validators.requiredTrue],
      }),
      addressCardForm: this.formBuilder.group({
        address: [
          '',
          Validators.compose([Validators.required, Validators.minLength(5)]),
        ],
        country: ['', Validators.required],
        state: ['', Validators.required],
        city: ['', Validators.required],
        zipCode: ['', Validators.required],
      }),
    });
  }

  // START API CALLS----------------------------------
  async getAllSavedCreditCards<T>() {
    const res = this.http.get<ApiResponse<T>>(
      `${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.VnumberApi.GetPaymentMethods}`
    );
    const data = await lastValueFrom(res);
    return data;
  }

  async getCreditCardById<T>(cardId: number) {
    const res = this.http.get<ApiResponse<T>>(
      `${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.VnumberApi.GetPaymentMethods}/${cardId}`
    );
    const data = await lastValueFrom(res);
    return data;
  }

  async editSavedCreditCard<T>(submitData: any) {
    const headers = new HttpHeaders().set('token', this.authService.getToken());

    const res = this.http.post<OldApiResponse<T>>(
      `${this.settings.getBaseUrlApi}/${this.config.controllerApiList.WebApi.AddCreditCard}`,
      submitData,
      { headers: headers }
    );
    const data = await lastValueFrom(res);
    return data;
  }

  // async editSavedCreditCard<T>(submitData: any) {
  //   const res = this.http.put<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.VnumberApi.GetPaymentMethods}`, submitData)
  //   const data = await lastValueFrom(res)
  //   return data
  // }

  async deleteCreditCardById<T>(cardId: number) {
    let params = new HttpParams().set('id', cardId);

    const res = this.http.delete<ApiResponse<T>>(
      `${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.VnumberApi.GetPaymentMethods}`,
      { params: params }
    );
    const data = await lastValueFrom(res);
    return data;
  }

  async createCreditCard<T>(submitData: any) {
    const headers = new HttpHeaders().set('token', this.authService.getToken());

    const res = this.http.post<OldApiResponse<T>>(
      `${this.settings.getBaseUrlApi}/${this.config.controllerApiList.WebApi.AddCreditCard}`,
      submitData,
      { headers: headers }
    );
    const data = await lastValueFrom(res);
    return data;
  }

  // END API CALLS ----------------------------------------

  
  creditCardValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const cleanedNumber = control.value.replace(/\s+/g, '').replace(/-/g, '');

    // Patterns for different types of cards
    const cardPatterns = {
      amex: /^3[47][0-9]{13}$/, // American Express: 15 digits, starts with 34 o 37
      dinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/, // Diners Club: 14 digits
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/, // Visa: 13 o 16 digits
      mastercard: /^5[1-5][0-9]{14}$/, // Mastercard: 16 digits
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/, // Discover: 16 digits
    };

    // Verify if it matches any card pattern
    const isValidFormat = Object.values(cardPatterns).some((pattern) =>
      pattern.test(cleanedNumber)
    );

    if (!isValidFormat) {
      return { invalidCardFormat: true };
    }

    // Luhn's algorithm
    const digits = cleanedNumber.split('').map(Number);
    let sum = 0;
    let isEven = false;

    for (let i = digits.length - 1; i >= 0; i--) {
      let digit = digits[i];

      if (isEven) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }

      sum += digit;
      isEven = !isEven;
    }

    if (sum % 10 !== 0) {
      return { invalidLuhnCheck: true };
    }

    return null;
  }
}
