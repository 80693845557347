import { Component, effect, ElementRef, HostListener, Inject, inject } from '@angular/core';
import { NavigationEnd, RouterModule, Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { AccountInfoService, AuthService, MY_ACCOUNT_CONFIG, MyAccountConfig } from '../../../../public-api';
import { IAccountInformation } from '../../../models/user-information.model';
import { RandomColorPipe } from 'lib-shared';


@Component({
  selector: 'app-profile-dropdown',
  standalone: true,
  imports: [TranslocoModule, RouterModule, RandomColorPipe],
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent {
  authService = inject(AuthService);
  accountInfoService = inject(AccountInfoService);
  
  userData: IAccountInformation | null = null;
  dropdownVisible = false;
  dropdownItems = [];

  constructor(private el: ElementRef, private router: Router, @Inject(MY_ACCOUNT_CONFIG) public config: MyAccountConfig) {
    this.router.events.subscribe(value => {
      if(value instanceof NavigationEnd)    
      if (this.dropdownVisible) {
        this.dropdownVisible = false;
      }
      });

      effect(() => {
        const signalValue = this.accountInfoService.accountInfoUpdatedSignal();
  
        if (signalValue === true) {
          this.getAccountInfo();
        }

        // Reset the signal to false after executing the action
          this.accountInfoService.onInfoUpdated(false);

      }, {allowSignalWrites: true});
  }

  ngOnInit(): void {
    this.dropdownItems = this.config.dropdownHeaderConfig.items
    this.getAccountInfo()
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {

    if (this.dropdownVisible) {
      if (!this.el.nativeElement.contains(event.target)) {
        this.dropdownVisible = false;
      }
    }
  }

  logout() {
    this.authService.logout()
  }

  async getAccountInfo() {
    try {
      const res =
        await this.accountInfoService.getAccountInfo<IAccountInformation>();

      if (res.isSuccessful && res.result) {
        this.userData = res.result;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
