import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { SettingsService } from 'lib-shared';
import { ControllerApiList } from '../../models/controllers/ControllerList';
import { FindCustomer } from '../../models/find-customer-model';


@Injectable({
  providedIn: 'root'
})
export class SmsValidationService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  async findCustomer(customerData: any) {
    const res = this.http.post<FindCustomer>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.FindCustomer}`, customerData)
    const data = await lastValueFrom(res)

    return data
  }

  async sendSMSCode(confirmationData: any) {
    const res = this.http.post<any>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.PortInApi.PhoneRequest}`, confirmationData)
    const data = await lastValueFrom(res)

    return data
  }

  async checkSMSCode(confirmationData: any) {
    const res = this.http.post<any>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.PortInApi.CheckSMSCode}`, confirmationData)
    const data = await lastValueFrom(res)

    return data
  }
}
