import { Component, inject, Inject } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';
import { MY_ACCOUNT_CONFIG } from '../../../config/my-account.config';

@Component({
  selector: 'app-profile-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslocoModule],
  templateUrl: './profile-sidebar.component.html',
  styleUrls: ['./profile-sidebar.component.scss'],
})
export class ProfileSidebarComponent {
  private config = inject(MY_ACCOUNT_CONFIG);

  primaryColor: string;
  colorMap: { [key: string]: string };

  sidebarItems = [];

  constructor(private router: Router) {
    this.primaryColor = this.config.theme.primaryColor;
    // Mapeo de colores
    this.colorMap = {
      burdeos: '#9E0B5C', // Color burdeos en HEX
      orange: '#ff7A01', // Color orange en HEX
    };
  }

  ngOnInit() {
    this.sidebarItems = this.config.sidebarConfig.items;
  }

  isActive(path: string): boolean {
    return this.router.url.startsWith(path);
  }

  getColor(): string {
    return this.colorMap[this.primaryColor] || '#808080'; // Color por defecto si no se encuentra
  }
}
