<div (click)="dropdownVisible = !dropdownVisible"
  class="flex items-center  relative ltr:pl-2 ltr:sm:pl-4 ltr:border-l rtl:pr-2 rtl:sm:pr-4 rtl:border-r border-greyLight cursor-pointer">
  <button class="text-white">
    @if (userData?.initials !== '') {
    <span class="w-8 sm:w-10 h-8 sm:h-10 rounded-full cursor-pointer flex items-center justify-center border"
    [style.background-color]="userData?.firstName | randomColor">
      {{ userData?.initials }}
    </span>
    } @else {
    <span class="w-8 sm:w-10 h-8 sm:h-10 rounded-full cursor-pointer flex items-center justify-center border">
      <img src="/images/icons/contact_2vnumber.svg" alt="user icon" class="w-4 sm:w-5">
    </span>
    }
  </button>

  <div class="group relative">
    <div class="hidden mlg:block group relative mx-2">
      <span class="text-black font-medium text-sm">{{ userData?.firstName }} {{ userData?.lastName}}</span>
      <p class="text-grey text-[10px]">{{ userData?.customerEmail }}</p>
    </div>

    @if (dropdownVisible) {
    <ul class="flex flex-col text-sm font-semibold absolute ltr:right-0 rtl:left-0 mt-5 bg-white p-2 rounded-b-xl border shadow w-max">
      @for (item of dropdownItems; track $index) {
        <li class="border-b border-greyLight hover:bg-greyLight rounded-xl">
          <a [routerLink]="item.path" class="flex items-center gap-3 px-8 py-4" (click)="$event.stopPropagation();">
            <img [src]="item.icon" alt="dropdown icon" class="">
            <span [transloco]="item.labelKey"></span>
          </a>
        </li>
      }
      <!-- LOG OUT -->
      <li class="p-2">
        <button (click)="logout()" class="w-full" transloco="btn-vn.log-out"></button>
      </li>
    </ul>
    }
  </div>
</div>