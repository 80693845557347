import { Routes } from '@angular/router';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { PortInVerificationComponent } from './core/shared/profile/port-in-verification/port-in-verification.component';
import { AuthGuard } from '../../../app-vnumber/src/app/guards/auth-guard.service';
import { ProfilePageComponent } from 'lib-my-account';

export const routes: Routes = [
  { path: '', redirectTo: 'verification', pathMatch: 'full', }, 
  { path: 'login', component: LoginPageComponent},
  { path: 'login/port-in/:token', component: LoginPageComponent, data: { isPortIn: true }},
  { path: 'not-found', component: NotFoundPageComponent },
  { path: 'profile', component: ProfilePageComponent, children: [
    { path: '', redirectTo: 'my-vnumbers', pathMatch: 'full' },
    { path: 'port-in', component: PortInVerificationComponent},
  ], canActivate: [AuthGuard] }, 
];