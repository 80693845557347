<section
  class="rounded-xl shadow-full border border-greyLight py-9 px-6 md:px-16 w-full"
>
  @if(loading){

  <div class="animate-pulse flex space-x-4 max-w-80">
    <div class="flex-1 space-y-6 py-1">
      <div class="h-2 bg-slate-200 rounded"></div>
      <div class="space-y-3">
        <div class="grid grid-cols-3 gap-4">
          <div class="h-2 bg-slate-200 rounded col-span-2"></div>
          <div class="h-2 bg-slate-200 rounded col-span-1"></div>
        </div>
        <div class="h-2 bg-slate-200 rounded"></div>
      </div>
    </div>
  </div>

  }@else{ @if(token){ @if(portInStatus?.statusId == 4 || portInStatus?.statusId
  == 5) {

  <h2
    class="text-2xl font-bold mb-2"
    transloco="globalweb.portin.verification.pending"
  >
    <!-- Verification Pending -->
  </h2>
  <span
    class="text-sm"
    [innerHTML]="
      'globalweb.portin.verification.pending-desc'
        | transloco : { phoneNumber: portInStatus?.phoneNumber }
    "
  >
    <!-- Your port-in request for the number 123456 is
    currently in progress. Please wait while we process your request. -->
  </span>
  }@else if(portInStatus?.statusId == 6){

  <h2
    class="text-2xl font-bold mb-2"
    transloco="globalweb.portin.verification.completed"
  >
    <!-- Verification Completed -->
  </h2>
  <div>
    <span
      class="text-sm" [innerHTML]="'globalweb.portin.verification.completed-desc'
          | transloco : { phoneNumber: portInStatus?.phoneNumber } ">
      <!-- Your port-in request for the number 12345679 has  been completed. -->
    </span>
  </div>

  <app-forwarding-settings
    [did]="portInStatus?.forwardingNumber"
    [forwardingPrefix]="portInStatus?.forwardingPrefix"
  />

  }@else if(portInStatus?.statusId == 7){

  <h2
    class="text-2xl font-bold mb-2"
    transloco="globalweb.portin.verification.failed"
  >
    Verification Failed
  </h2>
  <span class="text-sm" transloco="globalweb.portin.verification.failed-desc">
    Your port-in request for the number
    <span class="font-semibold">{{ portInStatus?.phoneNumber }}</span> has
    failed.
  </span>

  }@else{

  <h2
    class="text-2xl font-bold"
    transloco="globalweb.portin.verification.title"
  >
    <!--Verification-->
  </h2>
  <span class="text-sm" transloco="globalweb.portin.verification.instructions">
    <!--  Please enter the phone number you wish to port to our network. Make sure the
    number is valid and registered under your name to proceed with the porting
    process.-->
  </span>

  <form class="mt-8 space-y-6" (ngSubmit)="onSubmit()">
    <div class="flex flex-col md:flex-row">
      <div class="mb-6">
        <label for="phone" class="sr-only" transloco="globalweb.portin.form.labels.lb-phone"
          >Phone number</label
        >
        <div class="flex items-start border border-gray-300 rounded-md">
          <input
            id="phoneInput"
            name="phoneInput"
            type="tel"
            required
            #phoneInput
            class="flex-grow px-3 py-2 bg-transparent placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder=""
            [disabled]="codeSent"
            [(ngModel)]="phoneNumber"
          />
        </div>

        @if(codeSent){
        <div class="flex flex-col md:flex-row mt-4">
          <div class="mb-6">
            <label
              for="code"
              class="sr-only"
              transloco="globalweb.portin.form.labels.lb-code"
              >Verification Code</label
            >
            <input
              id="code"
              name="code"
              type="number"
              required
              class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Verification Code"
              [(ngModel)]="verificationCode"
            />
          </div>

          <div class="px-4">
            <button
              type="submit"
              [disabled]="verificationCode?.toString().length < 4"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#7146ff] hover:bg-[#5f3ad9] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#7146ff] disabled:bg-[#7146ff] disabled:opacity-60 disabled:cursor-not-allowed"
              transloco="globalweb.portin.form.buttons.verify"
            >
              Verify
            </button>
          </div>
        </div>
        @if(resendTimer > 0){
        <div class="mt-2 text-center md:text-start">
          <p
          [innerHTML]="'globalweb.portin.form.labels.resend' | transloco : { resendTimer: resendTimer }" ></p>
        </div>
        } }
      </div>
      @if(!codeSent){
      <div class="px-4">
        <button
          type="submit"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#7146ff] hover:bg-[#5f3ad9] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#7146ff]"
          transloco="globalweb.portin.form.buttons.submit"
        >
          Submit
        </button>
      </div>
      }
    </div>
  </form>

  } }@else{

  <h2 class="text-2xl font-bold" transloco="globalweb.portin.request.title">
    Port-in request
  </h2>
  <span class="text-sm" transloco="globalweb.portin.request.instructions">
    To initiate a port-in request, please contact our customer support team for
    assistance.
  </span>

  } @if (showPopUp) {
  <app-info-pop-up (closePopUpEvent)="onClosePopUp()">
    <section class="flex flex-col items-center">
      <div class="text-center">
        <h4
          class="text-2xl sm:text-4xl font-semibold text-orange"
          transloco="globalweb.portin.popups.information-title"
        >
          <!-- Information -->
        </h4>
      </div>
    </section>

    <section class="flex flex-col items-center">
      <div class="text-center">
        {{ popUpMessage }}
      </div>

      <div class="flex justify-center mt-4">
        <button
          (click)="onClosePopUp()"
          class="bg-orange rounded-full py-3 px-14 font-semibold text-white"
          transloco="globalweb.portin.form.buttons.btn-close" >
          <!-- Close -->
        </button>
      </div>
    </section>
  </app-info-pop-up>
  } }
</section>
