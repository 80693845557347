<main class="px-5 pb-16">
  <section class="flex justify-center py-3 sm:py-12">
    <div class="hidden sm:flex flex-col w-fit">
      <img src="/images/icons/personalInfo/shape.svg" alt="" class="w-20">
      <h2 class="text-center px-12 py-4 font-semibold" [transloco]="title + currentChildRoute "></h2>
      <img src="/images/icons/personalInfo/shape2.svg" alt="" class="w-32 ml-auto">
    </div>
  </section>

  <section class="flex flex-col lg:flex-row gap-8">
    <article class="w-full lg:max-w-[382px] lg:mx-auto">
      <!-- SIDEBAR -->
      <app-profile-sidebar></app-profile-sidebar>

      @if (isAddCreditVisible) {
        <!-- ADD CREDIT -->
        <div class="rounded-xl shadow-full border border-greyLight py-9 px-6 sm:px-12 w-full sm:mb-8">
          <div class="flex flex-wrap items-center justify-center">
            <h3 class="font-semibold" transloco="profile-vn.available-credit"></h3>
            <div class="flex gap-1">
              <img src="/images/icons/profile/positive-balance.svg" alt="icon" class="w-8">
              <span class="text-green text-2xl font-medium">$ {{ availableCredit.toFixed(2) }}</span>
            </div>
          </div>
          <button
            class="flex justify-center items-center bg-orange py-2 rounded-xl w-full mt-4 text-white uppercase font-semibold hover:scale-105"
            routerLink="/profile/add-credit">
            <span class="text-sm" transloco="btn-vn.add-credit"></span>
            <img src="/images/icons/arrow-icon.svg" alt="white arrow"
              class="w-[1.8rem] pb-1 h-fit rtl:-scale-x-100">
          </button>
        </div>
      }

      <!-- LOG OUT BUTTON DESKTOP-->
       <div class="hidden sm:block">
         <app-btn-logout (onLogOut)="logout()" [label]="logOut"></app-btn-logout>
        </div>
    </article>

    <article class="w-full mlg:w-2/3">
      <router-outlet></router-outlet>
    </article>

    <!-- LOG OUT BUTTON DESKTOP-->
    <div class="block sm:hidden">
      <app-btn-logout (onLogOut)="logout()" [label]="logOut" ></app-btn-logout>
    </div>
  </section>
</main>