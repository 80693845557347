import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import { environment } from '../../../../environments/environment';

export type AppName = 'vnumber' | 'globusim' | 'bglobal' | 'globalweb' | '011global';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private _config: any;
  private appNameSubject = new BehaviorSubject<AppName>('bglobal');
  private initializationComplete = new BehaviorSubject<boolean>(false);

  constructor(@Inject('APP_ENVIRONMENT') private environment: any) {
    this._config = environment
  }

  // Gets API route based on the provided key
  get getBaseUrlApi(): string {
    return this._config.MAIN_API_URL
  }

  get getHubBaseUrlApi(): string {
    return this._config.HUB_API_URL
  }

  get(key: any) {
    return this._config[key];
  }

  updateSettings(settings: { appName: AppName }): Promise<void> {
    return new Promise((resolve) => {
      if (settings.appName) {
        this.appNameSubject.next(settings.appName);
        this.initializationComplete.next(true);
      } else {
        console.warn("SettingsService: No appName provided in settings");
      }
      resolve();
    });
  }

  getAppName(): AppName {
    return this.appNameSubject.value;
  }

  waitForInitialization(): Promise<boolean> {
    return new Promise((resolve) => {
      this.initializationComplete.subscribe(complete => {
        if (complete) resolve(true);
      });
    });
  }
}
