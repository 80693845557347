<ul class="flex flex-col lg:flex-row space-x-4 xl:text-lg px-6">
  
  <li routerLink="" class="py-3 lg:py-0 block lg:hidden ml-4 lg:ml-0 hover:shadow-xl lg:hover:shadow-none hover:scale-105" (click)="onLinkClick()">
    <a routerLink="" routerLinkActive="font-semibold" class="" transloco="nav-links-vn.link-0"></a>
    </li>
    <li routerLink="get-your-number" class="py-3 lg:py-0 ml-4 lg:ml-0 hover:shadow-xl lg:hover:shadow-none hover:scale-105" (click)="onLinkClick()">
      <a routerLink="get-your-number" routerLinkActive="font-semibold" class="" transloco="nav-links-vn.link-1"></a>
  </li>
  <li routerLink="about-us" class="py-3 lg:py-0 border-t border-greyLight lg:border-none lg:hover:shadow-none hover:shadow-xl hover:scale-105" (click)="onLinkClick()">
    <a routerLink="about-us" routerLinkActive="font-semibold" class="" transloco="nav-links-vn.link-2"></a>
  </li>
  <li routerLink="faq" class="py-3 lg:py-0 border-t border-greyLight lg:border-none lg:hover:shadow-none hover:shadow-xl hover:scale-105" (click)="onLinkClick()">
    <a routerLink="faq" routerLinkActive="font-semibold" class="" transloco="nav-links-vn.link-3"></a>
  </li>
  <li routerLink="contact-us" class="py-3 lg:py-0 border-t border-greyLight lg:border-none lg:hover:shadow-none hover:shadow-xl hover:scale-105" (click)="onLinkClick()">
    <a routerLink="contact-us" routerLinkActive="font-semibold" class="" transloco="nav-links-vn.link-4"></a>
  </li>
</ul>