import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common'; 
import { LocalStorageList } from '../../helpers/enums/enums.keys';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {
  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  async setData(type: LocalStorageList | string, data: any, isJson: boolean = false): Promise<void> {
    if (this.isBrowser && typeof localStorage !== 'undefined') {
      await localStorage.setItem(type, isJson ? JSON.stringify(data) : data);
    }
  }

  getData(type: LocalStorageList | string, isJson: boolean = false): any {
    if (!this.isBrowser) {
      return null;
    }

    try {
      const data = localStorage.getItem(type);
      return isJson ? data !== undefined && data != null ? JSON.parse(data) : undefined : data;
    } catch (error) {
      return null;
    }
  }

  hasKey(key: LocalStorageList | string): boolean {
    if (!this.isBrowser) {
      return false;
    }
    return localStorage.getItem(key) !== null;
  }
  
  removeData(type: LocalStorageList): void {
    if (this.isBrowser) {
      localStorage.removeItem(type);
    }
  }

  
}