<aside select="sidebar" class="rounded-xl shadow-full border border-greyLight p-6 w-full mb-8">
  <ul class="flex flex-col gap-5">
    <li *ngFor="let item of sidebarItems" [class.router-link-active]="isActive(item.path)">
      <a [routerLink]="item.path" routerLinkActive="bg-grey text-white"
         class="flex px-9 py-3 hover:bg-greyLight rounded-xl gap-3 hover-primary"
         [style]="{'--primary-color': isActive(item.path) ? getColor() : '#efefef'}">
         @if (isActive(item.path)) {
           <img [src]="item.iconActive" alt="icon" class="w-6">
          }@else {
           <img [src]="item.icon" alt="icon" class="w-6">
         }
        <span class="text-sm font-semibold" transloco="{{ item.labelKey }}"></span>
      </a>
    </li>
  </ul>
</aside>