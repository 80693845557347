import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MY_ACCOUNT_CONFIG, MyAccountConfig } from '../config/my-account.config';

@Injectable({
  providedIn: 'root'
})
export class MyAccountApiService {
  constructor(
    private http: HttpClient,
    @Inject(MY_ACCOUNT_CONFIG) private config: MyAccountConfig
  ) {}

  getUserProfile() {
    return this.http.get(`${this.config.apiBaseUrl}/profile`);
  }

  updateProfile(data: any) {
    return this.http.put(`${this.config.apiBaseUrl}/profile`, data);
  }
}