import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { SettingsService } from 'lib-shared';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  appName: string = '';

  // appName mapping to Brand-Id
  private brandIdMap: Record<string, number> = {
    globusim: 1,
    wisesim: 2,
    '011global': 3,
    vnumber: 4,
    bglobal: 5,
  };

  constructor(
    private authService: AuthService,
    private settings: SettingsService
  ) {
    this.appName = this.settings.getAppName().toLowerCase();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // brand id validation
    const brandId = this.brandIdMap[this.appName] || 0;
    req = req.clone({
      headers: req.headers.set('Brand-Id', brandId.toString()),
    });

    if (this.authService.IsLogin()) {
      const url = req.url.toLowerCase();
      let authReq: HttpRequest<any> = req;

      // console.log("URL_INTERCEPTOR: ", url)
      if (url.includes('hub')) {
        const bearerToken = this.authService.getToken();
        authReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${bearerToken}`),
        });
      } else if (url.includes('webapi')) {
        const token = this.authService.getToken();
        if (token) {
          authReq = req.clone({
            headers: req.headers.set('Authorization', `Token ${token}`),
          });
        }
      } else {
        // If it does not match any of the above cases, we do not modify the application.
        return next.handle(req);
      }

      return next.handle(authReq).pipe(
        catchError((error: any) => {
          if (
            error instanceof HttpErrorResponse &&
            error.status === 401 &&
            !req.url.toLowerCase().includes('/login')
          ) {
            this.authService.logout();
          }
          return throwError(() => error);
        })
      );
    }

    return next.handle(req);
  }
}
