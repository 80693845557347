<div class="flex flex-col gap-12 py-4 px-5 border-t">
  <!-- FORWARDING SETTINGS -->
  <div class="border-x px-5">
    <!-- <h3 class="text-base font-semibold mb-2" transloco="profile-vn.my-vnumbers.forwarding.title"></h3>
    <p class="text-xs text-grey pb-2 border-b" transloco="profile-vn.my-vnumbers.forwarding.subtitle"></p> -->
    <div class="flex flex-col md:flex-row divide-y md:divide-x gap-2">
      <!-- SET UP CALLS -->
      <div class="flex flex-col w-full md:w-1/2 py-4">
        <span class="font-medium" transloco="globalweb.forwarding.internal-title">
          <!-- Simplify your connections! Send calls to your local phone  -->
        </span>
        <div class="flex flex-col gap-2 pt-2">
          @if (isEditCallForwarding) {
            <!-- PHONE -->
            <form [formGroup]="callForwardingForm" (ngSubmit)="saveChangesCallForwarding()" class="flex flex-col gap-2">
              <div class="flex gap-1">
                <!-- PREFIX INPUT -->
                <label for="prefixInput" class="hidden mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
                  transloco="globalweb.forwarding.form.labels.prefix"></label>
                <input type="tel" id="prefixInput" #prefixInput formControlName="prefix"
                  [placeholder]="'globalweb.forwarding.form.labels.prefix' | transloco" (keypress)="onKeyPress($event)"
                  (input)="onInputChange($event, 'prefix')"
                  class="{{
                    callForwardingForm.get('prefix')?.invalid && formSubmitted
                      ? 'border-red'
                      : 'border-greyDark'
                  }} block min-h-[auto] w-16 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
                <!-- PHONE INPUT -->
                <label for="phoneInput" class="hidden mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
             ></label>
                <input type="tel" id="phoneInput" #phoneInput formControlName="phone"
                  [placeholder]="'globalweb.forwarding.form.labels.phone-number' | transloco" (keypress)="onKeyPress($event)"
                  (input)="onInputChange($event, 'phone')"
                  class="{{
                    callForwardingForm.get('phone')?.invalid && formSubmitted
                      ? 'border-red'
                      : 'border-greyDark'
                  }} block min-h-[auto] w-36 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
              </div>

              <div class="relative flex gap-1">
                <!-- CONFIRM PREFIX INPUT -->
                <label for="confirmPrefixInput" class="hidden mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
                 ></label>
                <input type="tel" id="confirmPrefixInput" #prefixInput formControlName="confirmPrefix"
                  [placeholder]="'globalweb.forwarding.form.labels.confirm-prefix' | transloco" (keypress)="onKeyPress($event)"
                  (input)="onInputChange($event, 'confirmPrefix')"
                  class="{{
                    callForwardingForm.get('confirmPrefix')?.invalid &&
                    formSubmitted
                      ? 'border-red'
                      : 'border-greyDark'
                  }} block min-h-[auto] w-16 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
                <!-- CONFIRM PHONE INPUT -->
                <label for="confirmPhoneInput" class="hidden mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
                ></label>
                <input type="tel" id="confirmPhoneInput" #phoneInput formControlName="confirmPhone"
                  [placeholder]="'globalweb.forwarding.form.labels.confirm-phone-number' | transloco" (keypress)="onKeyPress($event)"
                  (input)="onInputChange($event, 'confirmPhone')"
                  class="{{
                    callForwardingForm.get('confirmPhone')?.invalid &&
                    formSubmitted
                      ? 'border-red'
                      : 'border-greyDark'
                  }} block min-h-[auto] w-36 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
                @if (callForwardingForm.errors?.['phoneMismatch'] &&
                callForwardingForm.get('confirmPhone')?.touched) {
                <small class="text-red absolute top-8" transloco="globalweb.forwarding.form.messages.phone-not-match"></small>
                }
              </div>
              <!-- SAVE & CANCEL BUTTONS -->
              <div class="flex gap-1 pt-2">
                <button transloco="globalweb.forwarding.form.buttons.save" type="submit"
                  class="px-2 py-1 text-xs my-auto border hover:border-green rounded-md hover:text-green"></button>
                <button transloco="globalweb.forwarding.form.buttons.cancel" type="button" (click)="cancelEditCallNumber()"
                  class="px-2 py-1 text-xs my-auto border hover:border-red rounded-md hover:text-red"></button>
              </div>
            </form>
          }@else {
            @if (did === 0 || !did) {
            <!-- ADD CALL NUMBER BUTTON -->
            <button transloco="globalweb.forwarding.form.buttons.add" (click)="editCallForwarding()"
              class="w-fit px-3 py-1 text-xs border hover:border-orange rounded-md hover:text-orange"></button>
            }@else {
            <!-- @for (num of number; track $index) { -->
            <div class="flex flex-1 items-center justify-between gap-2">
              <div class="flex items-center gap-3 py-1.5 px-4 border rounded min-w-48">
                <!-- <img [src]="num.countryName | appFlags" class="w-5"> -->
                <!-- <span class="text-sm font-semibold">{{ number | phoneFormat:num.mask:num.prefix }}</span> -->
                <span class="text-sm font-semibold">{{forwardingPrefix}} {{ number }}</span>
              </div>
            </div>
            <!-- EDIT CALL NUMBER BUTTON -->
            <button transloco="globalweb.forwarding.form.buttons.edit" (click)="editCallForwarding(number)"
              class="w-fit px-3 py-1 text-xs border hover:border-orange rounded-md hover:text-orange"></button>
            <!-- } -->

            } 
          }
        </div>
      </div>
    </div>
  </div>
</div>


@if (showPopUp) {
<app-info-pop-up [showPopUp]="showPopUp" (closePopUpEvent)="onClosePopUp()">
  <section class="flex flex-col items-center">
    <div class="text-center">
      <h4 class="text-2xl sm:text-4xl font-semibold text-orange">
        Information
      </h4>
    </div>
  </section>

  <section class="flex flex-col items-center">
    <div class="text-center">
      {{ popUpMessage }}
    </div>

    <div class="flex justify-center mt-4">
      <button (click)="onClosePopUp()" class="bg-orange rounded-full py-3 px-14 font-semibold text-white">
        Close
      </button>
    </div>
  </section>
</app-info-pop-up>
}