import { CommonModule } from '@angular/common';
import { Component, Inject, inject, PLATFORM_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  // popUpService = inject(PopUpService)
  textDir: string = 'ltr';

  onActivate(event: any) {
    if (this.platformId === 'browser') {
      window.scroll(0, 0);
    }
  }

  // onClosePopUp() {
  //   this.popUpService.closePopUp();
  // }
}
