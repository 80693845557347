import { Component, EventEmitter, inject, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { AuthService, InquirysService } from '../../../../../public-api';
import { UserInformation } from '../../../../models/user-information.model';
import { ClickOutsideDirective } from 'lib-shared';



@Component({
  selector: 'app-delete-account-modal',
  standalone: true,
  imports: [ReactiveFormsModule, TranslocoModule, ClickOutsideDirective],
  templateUrl: './delete-account-modal.component.html',
  styleUrl: './delete-account-modal.component.scss',
})
export class DeleteAccountModalComponent {
  @Output() closeModalEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  authService = inject(AuthService);
  inquiryService = inject(InquirysService);
  translateService = inject(TranslocoService);

  deleteForm: FormGroup;

  reasons: string[] = [
    'reason-1',
    'reason-2',
    'reason-3',
    'reason-4',
    'reason-5',
  ];

  userData: UserInformation | null = null;;
  formSubmitted: boolean = false;
  currentLang: string = "";
  showPasswordStep: boolean = false;
  passwordError: boolean = false;
  showPassword: boolean = false

  constructor(private fb: FormBuilder) {
    this.deleteForm = this.fb.group({
      reason: ['', Validators.required],
      otherReason: [''],
      password: [''],
    });
   }

  ngOnInit() {
    this.userData = this.authService.getCurrentUser();
    this.deleteForm.get('reason')?.valueChanges.subscribe((reason) => {
      const otherReasonControl = this.deleteForm.get('otherReason');
      if (reason === 'reason-5') {
        otherReasonControl?.setValidators(Validators.required);
      } else {
        otherReasonControl?.clearValidators();
      }
      otherReasonControl?.updateValueAndValidity();
    });

    if (this.showPasswordStep) {
      this.deleteForm.get('password')?.setValidators(Validators.required);
    };

    // Subscribe to language changes
    this.translateService.langChanges$.subscribe((lang: string) => {
      this.currentLang = lang; // Update currentLang when language changes
    });

    // Initialize currentLang with the current active language
    this.currentLang = this.translateService.getActiveLang();
  }

  async onSubmit() {
    this.formSubmitted = true;
    const customerEmail: UserInformation = this.authService.getCurrentUser();

    if (!this.deleteForm.valid) return;

    try {

      const password = this.deleteForm.value.password ?? "";
      let token2fa = localStorage.getItem('token2fa') || '';
      const isPasswordValid = await this.authService
        .login(customerEmail.email, password, token2fa)
        .catch(() => false); // If login fails, we return false

      if (!isPasswordValid) {
        this.passwordError = true;
        return;
      }

      const selectedReason = this.deleteForm.get('reason')?.value;
      let message: string | null | undefined;

      if (selectedReason === 'reason-5') {
        message = this.deleteForm.get('otherReason')?.value;
      } else {
        message = this.translateService.translate(
          'profile-vn.account-info.delete-account.' + selectedReason
        );
      }

      const jsonData = {
        languageISO: this.currentLang,
        message: message,
      };

      const res = await this.inquiryService.deleteAccount(jsonData);

      if (res.isSuccessful && (res.result as number) > 0) {
        this.onCloseModal(true);
      }
    } catch (error) {
      console.error('Error in account deletion process:', error);
      this.passwordError = true;
    } finally {
      this.formSubmitted = false;
    }
  }

  onCloseModal(param: boolean) {
    this.closeModalEvent.emit(param);
  }

  goBackToReasons() {
    this.showPasswordStep = false;
    this.passwordError = false;
    this.deleteForm.get('password')?.reset();
  }
}
