import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { FlagsPipe } from '../../../../pipes/flags.pipe';
import { PhoneFormatPipe } from '../../../../pipes/phone-format.pipe';
import { PortInService } from '../../../../services/port-in/port-in.service';
import { InfoPopUpComponent } from '../../pop-up/info-pop-up/info-pop-up.component';

 

@Component({
  selector: 'app-forwarding-settings',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RouterModule, TranslocoModule, InfoPopUpComponent],
  templateUrl: './forwarding-settings.component.html',
  styleUrl: './forwarding-settings.component.scss',
})
export class ForwardingSettingsComponent {
  // myVnumberService = inject(MyVnumbersService);

  @Input() did;
  @Input() forwardingPrefix;
  // @Output() updateForwarding: EventEmitter<IMyVNumbers> = new EventEmitter<IMyVNumbers>();
  @ViewChild('prefixInput') prefixInput!: ElementRef;
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  @ViewChild('inputEmail') inputEmail!: ElementRef;

  number;

  @Input() didid: number = 0;

  isEditCallForwarding: boolean = false;
  isEditSMSForwarding: boolean = false;
  formSubmitted: boolean = false;

  smsForwardingForm: FormGroup;
  callForwardingForm: FormGroup;

  portInService = inject(PortInService);

  route = inject(ActivatedRoute);
  token = this.route.snapshot.queryParamMap.get('sessionid');
  loading: boolean = false;
  showPopUp: boolean;
  popUpMessage: string = '';

  constructor(private fb: FormBuilder) {
    this.callForwardingForm = this.fb.group(
      {
        prefix: [
          '',
          [
            Validators.required,
            Validators.maxLength(3),
            Validators.pattern('^[0-9]*$'),
          ],
        ],
        phone: [
          '',
          [
            Validators.required,
            Validators.maxLength(15),
            Validators.pattern('^[0-9]*$'),
          ],
        ],
        confirmPrefix: [
          '',
          [
            Validators.required,
            Validators.maxLength(3),
            Validators.pattern('^[0-9]*$'),
          ],
        ],
        confirmPhone: [
          '',
          [
            Validators.required,
            Validators.maxLength(15),
            Validators.pattern('^[0-9]*$'),
          ],
        ],
      },
      { validators: this.phoneMatchValidator() }
    );
  }

  ngOnInit(): void {
    this.number = this.did;
   /* console.log("number", this.number);
    console.log("did", this.did)
    console.log("did id", this.didid)*/
  }

  phoneMatchValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newPhone = form.get('prefix')?.value + form.get('phone')?.value;
      const confirmPhone =
        form.get('confirmPrefix')?.value + form.get('confirmPhone')?.value;
      return newPhone === confirmPhone ? null : { phoneMismatch: true };
    };
  }

  onInputChange(event: Event, controlName: string) {
    const input = event.target as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[^0-9]/g, '');
    this.callForwardingForm
      .get(controlName)
      ?.setValue(sanitizedValue, { emitEvent: false });
  }

  onKeyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  editCallForwarding(num: number = 0) {
    setTimeout(() => {
      if (this.prefixInput) this.prefixInput.nativeElement.focus();
    }, 0);

    let selectedNumber = num;

    if (selectedNumber) {
      this.callForwardingForm.patchValue({
        prefix: this.forwardingPrefix,
        phone: selectedNumber,
        confirmPrefix: '',
        confirmPhone: '',
      });
    }

    this.isEditCallForwarding = true;
  }

  // editSMSEmail() {
  //   setTimeout(() => {
  //     if (this.inputEmail) this.inputEmail.nativeElement.focus(); // Focusing the input
  //   }, 0);

  //   this.smsForwardingForm.patchValue({
  //     confirmEmail: ''
  //   })

  //   this.isEditSMSForwarding = true
  //   this.smsForwardingForm.get('email')?.enable();
  // };

  // cancelEditSMSmail() {
  //   this.isEditSMSForwarding = false;
  //   this.smsForwardingForm.get('email')?.disable()
  // }

  cancelEditCallNumber() {
    this.isEditCallForwarding = false;
  }

  async saveChangesCallForwarding() {
    this.formSubmitted = true;
    if (this.callForwardingForm.valid) {
      const { prefix, phone } = this.callForwardingForm.value;

      const jsonData = {
        forwardingPrefix: prefix,
        forwardingNumber: phone,
      };
  
      try {
        this.loading = true;
        let result = await this.portInService.updateForwardinData<boolean>(this.token, jsonData);
        this.loading = false;
        if (result && result.isSuccessful) {
          if (result.result) {
           console.log(result.result);
           this.isEditCallForwarding = false;
          } else {
            this.popUpMessage =
              'There was a problem and we were unable to update the information.';
            this.openPopUp();
          }
        } else {
          if (result.isError) {
            this.popUpMessage = result.errorMessage;
            this.openPopUp();
          } else {
            const concatenatedMessages = result.messages
              .map((msg) => msg.message)
              .join(', ');
            this.popUpMessage = concatenatedMessages;
            this.openPopUp();
          }
        }
      } catch (error) {
        if (error && error['error']) {
          const concatenatedMessages = error['error']['messages']
            .map((msg) => msg.message)
            .join(', ');
          this.popUpMessage = concatenatedMessages;
          this.openPopUp();
        }
      }


      this.formSubmitted = false;
    }

    
  }

  
  openPopUp() {
    this.showPopUp = true; 
  }

  
  onClosePopUp() {
    this.showPopUp = false;
  }

  // saveChangesSMSForwarding() {
  //   this.formSubmitted = true

  //   if (this.smsForwardingForm.valid) {

  //     const { email } = this.smsForwardingForm.value

  //     const jsonData = {
  //       didid: this.did?.didid,
  //       email: email
  //     }

  //     this.updateForwardinData(jsonData)
  //     this.formSubmitted = false;
  //   }
  // }

  // async updateForwardinData(data: any) {

  //   try {
  //     const res = await this.myVnumberService.updateForwardingNumber(data);

  //     if (res.isSuccessful && res.result) {
  //       this.getMyVNumbers()
  //       this.cancelEditCallNumber()
  //       this.cancelEditSMSmail()
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // async getMyVNumbers() {
  //   try {
  //     const res = await this.myVnumberService.getMyVNumbers<IMyVNumbers[]>()

  //     if (res.isSuccessful) {
  //       let result = res.result.find(i=>i.didid === this.didid)
  //       this.number = result?.forwardingNumbers ?? result?.forwardingNumbers
  //       this.smsEmail = result?.smsEmail ?? result?.smsEmail;
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }
}
