import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SettingsService } from 'lib-shared';
import { ApiResponse } from '../../models/base/ApiResponse';
import { MY_ACCOUNT_CONFIG, MyAccountConfig } from '../../config/my-account.config';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InquirysService {

  private settings = inject(SettingsService);
  private config = inject(MY_ACCOUNT_CONFIG);
  private http = inject(HttpClient);

  constructor() { }

  async createSupportSubject<T>(dataCustomer: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.Inquiry}/support-customer`, dataCustomer)
    const data = await lastValueFrom(res)
    return data    
  }

  async deleteAccount<T>(dataCustomer: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.Inquiry}/cancel-customer`, dataCustomer)
    const data = await lastValueFrom(res)
    return data    
  }

  async contactUsInquiry<T>(dataCustomer: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.Inquiry}/create-lead`, dataCustomer)
    const data = await lastValueFrom(res)
    return data
  }
}
