import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SettingsService } from 'lib-shared';
import { lastValueFrom } from 'rxjs';
import { ApiResponse, MY_ACCOUNT_CONFIG } from '../../../public-api';

@Injectable({
  providedIn: 'root'
})
export class BillingHistoryService {
  private config = inject(MY_ACCOUNT_CONFIG)
  constructor(private http: HttpClient, private settings: SettingsService) {}

  async getBillingHistory<T>(page: number = 1, records: number = 5) {

    let params = new HttpParams()
    .set('pageIndex', page)
    .set('totalRecordsPerPage', records);

    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.VnumberApi.GetBillingHistory}`, { params: params,})
    const data = lastValueFrom(res)
    return data
  }

  async sendEmailReceipt<T>(transactionID: number, lang: string) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.VnumberApi.GetBillingHistory}/send-email`, { transactionID: transactionID, languageIso: lang })
    const data = lastValueFrom(res)
    return data
  }
}
