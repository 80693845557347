import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { MY_ACCOUNT_CONFIG } from '../../../config/my-account.config';
// import { SpinnerSmallComponent } from "../loading/spinner-small/spinner-small.component";

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [
    TranslocoModule, 
    // SpinnerSmallComponent
  ],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent {
  private config = inject(MY_ACCOUNT_CONFIG);

  @Input() pageIndex = 0;
  @Input() isLoading = false;
  @Input() totalRecordsPerPage = 0;
  @Input() hasMoreRecords: boolean = true;
  @Output() pageChange = new EventEmitter<{pageIndex: number, totalRecordsPerPage: number}>();

  primaryColor: string = this.config.theme.primaryColor;
  
  handlePrevious(): void {
    if (this.pageIndex > 1) {
      this.pageIndex--;
      this.emitPageChange();
    }
  }

  handleNext(): void {
    if (this.hasMoreRecords) {
      this.pageIndex++;
      this.emitPageChange();
    }
  }

  private emitPageChange(): void {
    this.pageChange.emit({
      pageIndex: this.pageIndex,
      totalRecordsPerPage: this.totalRecordsPerPage
    });
  }
}
