import { inject, Injectable, signal } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { SettingsService } from 'lib-shared';
import { ApiResponse } from '../../models/base/ApiResponse';
import { MY_ACCOUNT_CONFIG } from '../../config/my-account.config';


@Injectable({
  providedIn: 'root'
})
export class AccountInfoService {
  private config = inject(MY_ACCOUNT_CONFIG);

  addCreditSignal = signal<boolean>(false);
  accountInfoUpdatedSignal = signal<boolean>(false);

  constructor(private http: HttpClient, private settings: SettingsService) {}

  async getAccountInfo<T>() {
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.GetAccountInfo}`)
    const data = await lastValueFrom(res);
    return data
  }
  
  async updateAccountInfo<T>(patchOperations: any[]) {
    const res = this.http.patch<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.GetAccountInfo}`, patchOperations)
    const data = await lastValueFrom(res);
    return data
  }

  async verifyEmailChange<T>(customerData: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.VerifyEmailChange}`, customerData)
    const data = await lastValueFrom(res)
    return data
  }

  async validateCustomerEmail<T>(token: any) {

    let params = new HttpParams().set('token', token)

    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.ValidateCustomerEmail}`, {params: params})
    const data = await lastValueFrom(res)
    return data
  }

  onCreditAdded(info: boolean) {
    this.addCreditSignal.set(info)
  }

  onInfoUpdated(info: boolean) {
    this.accountInfoUpdatedSignal.set(info)
  }
}
