import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { InfoPopUpComponent } from '../../pop-up/info-pop-up/info-pop-up.component';
import { AuthService } from 'lib-my-account';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [
    InfoPopUpComponent,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
    // SpinnerComponent,
  ],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss',
})
export class LoginFormComponent {
  formSubmitted: boolean = false;
  showPopUp: boolean = false;
  isLoading: boolean = false;
  showPassword: boolean = false;
  popUpMessage: string = '';

  //injects
  route = inject(ActivatedRoute);
  router = inject(Router);
  formBuilder = inject(FormBuilder);
  authService = inject(AuthService);

  loginForm = this.formBuilder.group({
    username: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

  token: string = this.route.snapshot.params['token'];
  isPortIn: boolean = false;

  constructor() {}

  async ngOnInit() {
    this.route.data.subscribe((data) => {
      this.isPortIn = !!data['isPortIn'];
      if (this.isPortIn) {
        this.LoginWithTokenPortIn();
      }
    });
  }

  async LoginWithTokenPortIn() {
    try {
      if (this.token) {
        const res = await this.authService.portInLogin(this.token);
        console.log(res);

        if (res && this.authService.IsLogin()) {
          this.formSubmitted = false;
          this.router.navigate(['/profile/port-in'], { queryParams: { sessionid: this.token } });
        }
      }
    } catch (error) {
      this.isLoading = false;
      console.log(error);

      if (error.status === 401) {
        this.showPopUp = true;
        this.popUpMessage = error.error.messageCode.toLowerCase();
      }

      this.token = 'fail';
    } finally {
      this.isLoading = false;
    }
  }
  validateToken() {}

  onClosePopUp() {
    this.showPopUp = false;
  }

  async onSubmit() {
    this.formSubmitted = true;
    this.isLoading = true;
    console.log(this.loginForm);
    try {
      if (this.loginForm.valid) {
        const res = await this.authService.login(
          this.loginForm.get('username').value,
          this.loginForm.get('password').value
        );

        if (res && this.authService.IsLogin()) {
          this.formSubmitted = false;
          document.location.href = '/profile';
        } else {
          console.log(res);
        }
      }
    } catch (error) {
      this.isLoading = false;
      console.log(error);

      if (error.status === 401) {
        this.showPopUp = true;
        this.popUpMessage = error.error.messageCode.toLowerCase();
      }
    } finally {
      this.isLoading = false;
    }
  }
}
