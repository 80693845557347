import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiMessageCode, MY_ACCOUNT_CONFIG, TwoFactorVerificationService } from '../../../../public-api';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-two-fa-verification-page',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslocoModule],
  templateUrl: './two-fa-verification-page.component.html',
  styleUrl: './two-fa-verification-page.component.scss',
})
export class TwoFaVerificationPageComponent {
  private twoFactorService = inject(TwoFactorVerificationService);
 private config = inject(MY_ACCOUNT_CONFIG);

  email: string;
  token2fa: string;
  emailSent: boolean = false;
  isLoading: boolean = false;
  errorCode: string = '';
  primaryColor: string = this.config.theme.primaryColor;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.forEach((params) => {
      (this.email = params['email']), (this.token2fa = params['token']);
    });
  }

  async enableTwoFactor() {
    this.isLoading = true;
    try {
      const res = await this.twoFactorService.enable2Factor(this.token2fa);

      if (res.isSuccessful && res.result) {
        this.emailSent = true;
      }
    } catch (error) {
      const apiMessageCode = ApiMessageCode.MessageCode;
      const message = error.error.messages[0];

      if (message.code === apiMessageCode.tokenExpired) {
        this.errorCode = 'token-expired';
      }
    } finally {
      this.isLoading = false;
    }
  }

  skipTwoFactor() {
    this.router.navigate(['/login']); // O redirige a donde necesites
  }

  verifyToken() {
    if (this.token2fa) {
      localStorage.setItem('token2fa', this.token2fa);
      this.router.navigate(['/login']);
    } else {
      alert('Please enter a valid token.');
    }
  }
}
