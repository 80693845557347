import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { AuthService } from "lib-my-account";

@Injectable({
  providedIn: "root",
})
export class AuthGuard  {
  private authService = inject(AuthService);
  private router = inject(Router);

  constructor() {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.authService.IsLogin()) {
      return true;
    } else {
      window.location.href = '/login';
      return false;
    }
  }
 
}